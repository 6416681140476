<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field deposit">
            <ul class="content">
              <li class="item select">
                <ejs-dropdownlist
                  v-model="searchConditions.dateDiv"
                  :dataSource="searchOptions.dateDiv"
                  :allowFiltering="false"
                  :fields="commonCodeFields"
                  cssClass="lookup-condition-dropdown"
                />
              </li>
              <li class="item dateRange">
                <input-date-range
                  v-model="inputDateRangeValue"
                  type="lookup-condition"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              입금자/법인명
            </div>
            <ul class="content">
              <li class="item form-group input">
                <div class="form">
                  <input-text
                    ref="memberName"
                    v-model="searchConditions.memberName"
                    @keydown.enter="onDepositViewClicked"
                  />
                </div>
              </li>
            </ul>
          </li>
          <li v-if="false" class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        value="true"
                        v-model="searchConditions.isApplyByDate"
                      />
                      <i></i>
                      <div class="label">일자적용</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">
              {{ labels.cardNo }}
            </div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchConditions.cardNo"
                  @keydown.native="formPreventEnterEvent"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              buttonDiv="GET"
              :isShortcutButton="true"
              :ignore="isPopupOpened"
              @click.native="onDepositViewClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              buttonDiv="GET"
              :is-icon-custom="true"
              @click.native="onSearchDetailPopupOpen"
          >
            상세검색
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <ReportView ref="reportViewComponent" :isPopup="true" />
      <article
        class="body-article"
        :class="
          isDepositDetailViewOpened
            ? $t('className.reservation.reservationDetailViewOpened')
            : ''
        "
      >
        <div class="article-left">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-01">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">선수금 목록</div>
                <div class="header-caption">[{{ count }}건]</div>
              </div>
              <div class="header-right">
                <ul class="header-button">
                  <li class="add">
                    <erp-button
                        buttonDiv="SAVE"
                        :ignore="isPopupOpened"
                        :is-custom-shortcut-button="true"
                        :shortcut-key="'DepositRegistration.shortcuts.save'"
                        :shortcut="{key:'F3'}"
                        :isIconCustom="true"
                        @click.native="onDepositAddButtonClicked"
                    >
                      추가
                    </erp-button>
                  </li>
                  <li>
                    <erp-button
                        buttonDiv="GET"
                        @click.native="onDepositUseReturnListButtonClicked"
                        :isIconCustom="true"
                    >
                      사용/환불내역
                    </erp-button>
                  </li>
                  <li class="print">
                    <erp-button
                        buttonDiv="PRINT"
                        :is-shortcut-button="true"
                        @click.native="print"
                        :isIconCustom="true"
                        :ignore="isPopupOpened"
                    >
                      인쇄
                    </erp-button>
                  </li>
                  <li class="print">
                    <erp-button
                        buttonDiv="FILE"
                        @click.native="onClickExcel"
                    >
                      Excel
                    </erp-button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="section-body">
              <ejs-grid-wrapper
                ref="depositListGrid"
                :allowResizing="true"
                :dataSource="depositList"
                :selectionSettings="selectionSettings"
                :allowExcelExport="true"
                :columns="depositListGridColumn"
                :provides="grid"
                :aggregates="depositListGridAggregates"
                :allowPaging="true"
                :pageSettings="depositListPageSettings"
                :isAutoSelectRow="false"
                @dataBound="onDepositListGridDataBound"
                @headerCellInfo="depositListGridQueryHeaderInfo"
                @queryCellInfo="depositListGridQueryCellInfo"
                @recordClick="onDepositListGridRecordClick"
                @rowSelected="onDepositListGridRowSelected"
                @actionComplete="depositListGridActionComplete"
              />
            </div>
          </section>
        </div>
        <div class="article-right">
          <!-- 아코디언 : accordion / 닫힘 : close -->
          <section class="article-section section-02">
            <div class="section-header">
              <div class="header-left">
                <div class="header-title">선수금 정보</div>
              </div>
            </div>
            <div class="section-caption">
              <ul class="caption-button">
                <li class="save keyColor">
                  <erp-button
                      buttonDiv="SAVE"
                      :is-shortcut-button="true"
                      :isIconCustom="true"
                      :ignore="isPopupOpened || !isDepositDetailViewOpened"
                      @click.native="onDepositSaveButtonClicked"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      buttonDiv="DELETE"
                      :is-shortcut-button="true"
                      :isIconCustom="true"
                      :ignore="isPopupOpened || !isDepositDetailViewOpened"
                      @click.native="onDepositDeleteButtonClicked"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="reset">
                  <erp-button
                      buttonDiv="GET"
                      @click.native="onDepositAddButtonClicked"
                      :isIconCustom="true"
                  >
                    초기화
                  </erp-button>
                </li>
                <li>
                  <erp-button
                      buttonDiv="GET"
                      @click.native="onChangeHistoryButtonClicked"
                      :isIconCustom="true"
                  >
                    변경이력
                  </erp-button>
                </li>
                <li class="close">
                  <erp-button
                      buttonDiv="CLOSE"
                      @click.native="onDepositDetailViewClosed"
                      :isIconCustom="true"
                  >
                    닫기
                  </erp-button>
                </li>
              </ul>
            </div>
            <div class="section-body" v-show="isDepositDetailViewOpened">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-0201">
                  <div class="body-data">
                    <div class="data-outer">
                      <div class="data-inner">
                        <ul class="data-content">
                          <li class="field depositDate">
                            <!-- 필수 : required -->
                            <div class="title required">입금일자</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group date">
                                  <div class="form">
                                    <input-date
                                      ref="incomDate"
                                      format="YYYY-MM-DD"
                                      type="lookup-condition"
                                      v-model="depositInfo.incomDate"
                                    />
                                  </div>
                                </li>
                                <li class="item form-group text">
                                  (<span
                                    :style="{
                                      color: getDayOfWeekCaptionColor(
                                        new Date(depositInfo.incomDate)
                                      ),
                                    }"
                                    >{{
                                      getDayOfWeekCaption(
                                        new Date(depositInfo.incomDate)
                                      )
                                    }}</span
                                  >)
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field kind">
                            <!-- 필수 : required -->
                            <div class="title required">선수금종류</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group select">
                                  <div class="form">
                                    <ejs-dropdownlist
                                      ref="depositKind"
                                      v-model="depositInfo.depositKind"
                                      :dataSource="
                                        searchOptions.depositKindInputOptions
                                      "
                                      :allowFiltering="false"
                                      :fields="commonCodeFields"
                                      cssClass="body-data-dropdown"
                                    />
                                  </div>
                                </li>
<!--                                <li class="item form-group check">-->
<!--                                  &lt;!&ndash; 포커스 : focus &ndash;&gt;-->
<!--                                  <ul class="check">-->
<!--                                    <li>-->
<!--                                      <label>-->
<!--                                        <input-->
<!--                                          type="checkbox"-->
<!--                                          v-model="depositInfo.indctTrgetFlag"-->
<!--                                        />-->
<!--                                        <i></i>-->
<!--                                        <div class="label">소득공제</div>-->
<!--                                      </label>-->
<!--                                    </li>-->
<!--                                  </ul>-->
<!--                                </li>-->
                              </ul>
                            </div>
                          </li>
                          <li class="field store">
                            <!-- 필수 : required -->
                            <div class="title required">매장</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group">
                                  <div class="form">
                                    <ejs-dropdownlist
                                      ref="storeCode"
                                      v-model="depositInfo.storeCode"
                                      :dataSource="
                                        searchOptions.storeCodeOptions
                                      "
                                      :allowFiltering="false"
                                      :fields="commonCodeFields"
                                      cssClass="body-data-dropdown"
                                    />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field depositName">
                            <!-- 필수 : required -->
                            <div class="title required">입금자명</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group input">
                                  <div class="form">
                                    <input-text
                                        ref="memberName"
                                        v-model="depositInfo.memberName"
                                        @change="onMemberNameChanged"
                                    />
                                  </div>
                                </li>
                                <li class="item form-group button">
                                  <ul class="button">
                                    <li class="search">
                                      <erp-button
                                        button-div="GET"
                                        @click.native="
                                          onMemberPopupOpen(
                                            true,
                                            memberPopupType.RESV,
                                            depositInfo.memberName,
                                            null
                                          )
                                        "
                                      >
                                        검색
                                      </erp-button>
                                    </li>
                                  </ul>
                                </li>
                                <li class="item form-group text memberGrade">
                                  {{
                                    commonCodesGetComName(
                                      "MEMBER_DIV",
                                      depositInfo.memberDiv
                                    )
                                  }}
                                </li>
                                <li class="item form-group text">
                                  {{ depositInfo.memberNo }}
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field">
                            <!-- 필수 : required -->
                            <div class="title">법인명</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group">
                                  <div class="form">
                                    <input-text
                                      v-model="depositInfo.cprName"
                                      @keydown.native="formPreventEnterEvent"
                                    />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field">
                            <!-- 필수 : required -->
                            <div class="title">연락처</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group">
                                  <div class="form">
                                    <component-telephone
                                      ref="rcverInfo"
                                      v-model="depositInfo.contactTel"
                                      :max-length="11"
                                    />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field groupName">
                            <!-- 필수 : required -->
                            <div class="title">단체명</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group input">
                                  <div class="form">
                                    <input-text
                                      v-model="depositInfo.grpName"
                                      @keydown.native="formPreventEnterEvent"
                                      @change="onGrpNameChanged"
                                    />
                                  </div>
                                </li>
                                <li class="item form-group button">
                                  <ul class="button">
                                    <li class="search">
                                      <erp-button
                                        button-div="GET"
                                        @click.native="onGroupPopupOpen(true)"
                                      >
                                        검색
                                      </erp-button>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field reservationNumber">
                            <!-- 필수 : required -->
                            <div class="title">예약번호</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group input">
                                  <div class="form">
                                    <input-text
                                      v-model="depositInfo.resveNo"
                                      @keydown.native="formPreventEnterEvent"
                                      @change="onResveNoChange"
                                    />
                                  </div>
                                </li>
                                <li class="item form-group button">
                                  <ul class="button">
                                    <li class="search">
                                      <erp-button
                                        button-div="GET"
                                        @click.native="viewReservationInfo"
                                      >
                                        검색
                                      </erp-button>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li class="field remarks">
                            <!-- 필수 : required -->
                            <div class="title">비고</div>
                            <div class="content">
                              <ul class="row">
                                <li class="item form-group">
                                  <div class="form">
                                    <input-textarea
                                      v-model="depositInfo.remarks"
                                    />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                        <div class="border-left"></div>
                        <div class="border-right"></div>
                        <div class="border-top"></div>
                        <div class="border-bottom"></div>
                      </div>
                    </div>
                    <div class="border-left"></div>
                    <div class="border-right"></div>
                    <div class="border-top"></div>
                    <div class="border-bottom"></div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-0202">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">입금 목록</div>
                      <div class="header-caption">[{{ incomCount }}건]</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="add">
                          <erp-button
                            button-div="SAVE"
                            @click.native="onDepositIncomPopupOpenClicked"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="cancel">
                          <erp-button
                              button-div="DELETE"
                              @click.native="depositIncomCancelButtonClicked"
                          >
                            입금취소
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="depositIncomListGrid"
                        :allowResizing="true"
                        :dataSource="depositIncomList"
                        :selectionSettings="selectionSettings"
                        :allowExcelExport="true"
                        :columns="depositIncomListGridColumn"
                        :provides="grid"
                        :aggregates="depositIncomListGridAggregates"
                        @headerCellInfo="depositIncomListGridqueryHeaderInfo"
                        @queryCellInfo="depositIncomListGridqueryCellInfo"
                        @recordClick="onDepositIncomListGridRecordClick"
                        @actionComplete="depositIncomListGridActionComplete"
                      />
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-0203">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">선불카드 목록</div>
                      <div class="header-caption">[{{ cardCount }}건]</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="add">
                          <erp-button
                            button-div="SAVE"
                            @click.native="onDepositCardPopupOpenClicked"
                          >
                            추가
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="depositCardListGrid"
                        :allowResizing="true"
                        :dataSource="depositCardList"
                        :selectionSettings="selectionSettings"
                        :allowExcelExport="true"
                        :columns="depositCardListGridColumn"
                        :provides="grid"
                        :isAutoSelectCell="false"
                        @headerCellInfo="depositCardListGridHeaderCellInfo"
                        @queryCellInfo="depositCardListGridQueryCellInfo"
                        @recordClick="onDepositCardListGridRecordClick"
                        @actionComplete="depositCardListGridActionComplete"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </section>
        </div>
      </article>
    </div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="false"
      :visible="false"
      width="387"
      v-show="isSearchDetailPopupOpen"
    >
      <div class="window lookupDetail-advancePaymentRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">
                    {{ labels.remarks }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <input-text
                        v-model="searchConditions.remarks"
                        @keydown.native="formPreventEnterEvent"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.depositKind }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.depositKind"
                        :dataSource="searchOptions.depositKindOptions"
                        :fields="commonCodeFields"
                        :allowFiltering="false"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">
                    {{ labels.storeCode }}
                  </div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.storeCode"
                        :dataSource="searchOptions.tsStoreBsnOptions"
                        :fields="commonCodeFields"
                        :allowFiltering="false"
                        cssClass="lookupDetail-condition-dropdown"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title"></div>
                  <ul class="content">
                    <li class="item">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              v-model="searchConditions.balanceAmtFlag"
                            />
                            <i></i>
                            <div class="label">
                              {{ labels.balanceAmtFlag }}
                            </div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  buttonDiv="GET"
                  :isShortcutButton="true"
                  :ignore="isPopupOpened"
                  @click.native="onDepositDetailViewClicked"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  buttonDiv="GET"
                  @click.native="onInitButtonClicked"
                  :isIconCustom="true"
              >
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="onSearchDetailPopupClose"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <deposit-incom-popup
      v-if="isDepositIncomPopupOpen"
      ref="depositIncomPopup"
      @popupClosed="depositIncomPopupClosed"
    />
    <deposit-card-popup
      v-if="isDepositCardPopupOpen"
      ref="depositCardPopup"
      @popupClosed="depositCardPopupClosed"
    />
    <deposit-use-history-popup
      v-if="isDepositUseHistoryPopupOpen"
      ref="depositUseHistoryPopup"
      @popupClosed="depositUseHistoryPopupClosed"
    />
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
    <group-popup
      v-if="isGroupPopupOpen"
      ref="groupPopup"
      @popupClosed="onGroupPopupClosed"
    />
    <reservation-info-popup
      v-if="isReservationInfoPopupOpen"
      ref="reservationInfoPopup"
      @popupConfirmed="onReservationInfoPopupConfirmed"
      @popupClosed="onReservationInfoPopupClose"
    />
    <pay-cancel-popup
      v-if="isPayCancelPopupOpen"
      ref="payCancelPopup"
      @popupClosed="payCancelPopupClosed"
    />
    <history-popup
      v-if="isHistoryPopupOpen"
      ref="historyPopup"
      @popupClosed="historyPopupClosed"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.deposit .content .item.select{width:120px}
body .appContent .body-article .article-left,body .appContent .body-article .article-right{transition:all .3s ease-out}
body .appContent .body-article .article-left{width:100%}
body .appContent .body-article .article-right{width:480px;margin:0 -480px 0 0}
body .appContent .body-article.dev-reservation-detail-view-opened .article-left{width:calc(100% - 480px)}
body .appContent .body-article.dev-reservation-detail-view-opened .article-right{margin:0}
body .appContent .article-section.section-01 .section-body{overflow:hidden;border:none}
body .appContent .article-section.section-02 .section-body{overflow-y:scroll}
body .appContent .article-section.section-02 .body-article{height:auto}
body .appContent .article-section.section-02 .body-article .article-section{height:auto}
body .appContent .article-section.section-02 .body-article .section-header{width:auto}
body .appContent .article-section.section-02 .body-article .section-body{overflow:visible;border:none}
body .appContent .article-section.section-0201 .body-data .field .title{width:90px}
body .appContent .article-section.section-0201 .body-data .field .content{width:calc((100% - 90px) + 1px)}
body .appContent .article-section.section-0201 .body-data .field.depositDate{width:calc(100% + 1px)}
body .appContent .article-section.section-0201 .body-data .field.depositDate .content .item.date{width:calc((100% - 90px) / 2 + 1px)}
body .appContent .article-section.section-0201 .body-data .field.kind{width:calc(100% + 1px)}
/*body .appContent .article-section.section-0201 .body-data .field.kind .content .item.select{width:calc((100% - 90px) / 2 + 1px)}*/
body .appContent .article-section.section-0201 .body-data .field.store{width:calc(100% + 1px)}
body .appContent .article-section.section-0201 .body-data .field.depositName{width:calc(100% + 1px)}
body .appContent .article-section.section-0201 .body-data .field.depositName .content .item.input{width:90px}
body .appContent .article-section.section-0201 .body-data .field.depositName .content .item.text.memberGrade{width:120px}
body .appContent .article-section.section-0201 .body-data .field.remarks{width:calc(100% + 1px)}
body .appContent .article-section.section-0201 .body-data .field.groupName .content .item.input{width:90px}
body .appContent .article-section.section-0201 .body-data .field.reservationNumber .content .item.input{width:90px}
body .appContent .article-section.section-0202 .section-body .body-grid{height:180px}
body .appContent .article-section.section-0203 .section-body .body-grid{height:180px}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import {
  Aggregate,
  ExcelExport,
  Filter,
  ForeignKey,
  Group,
  Resize,
  Page,
} from "@syncfusion/ej2-vue-grids";
import InputDate from "@/components/common/datetime/InputDate";

import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetColorValue,
  commonCodesGetCommonCode,
  commonCodesGetComName,
} from "@/utils/commonCodes";
import {
  getDayOfWeekCaption,
  getDayOfWeekCaptionColor,
  getFormattedDate,
  getWeekdayWeekendCaption,
  getWeekdayWeekendCaptionColor,
} from "@/utils/date";
import { numberWithCommas } from "@/utils/number";
import { formPreventEnterEvent, validateFormRefs } from "@/utils/formUtil";
import { getTableUseStore } from "@/api/storeTableRegistration";
import { getGroupList } from "@/api/group";

import ReportView from "@/components/common/report/ReportView";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import payCancelPopup from "@/components/popups/PayCancelPopup";
import depositIncomPopup from "./popup/DepositIncomPopup";
import depositCardPopup from "./popup/DepositCardPopup";
import depositUseHistoryPopup from "./popup/DepositUseHistoryPopup";
import memberSelectPopup from "@/views/common/MemberSelectPopup";
import groupPopup from "@/views/golf-reservation/popup/GroupPopup";
import reservationInfoPopup from "@/views/common/ReservationInfoPopup";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import { mapGetters } from "vuex";

import ErpButton from "@/components/button/ErpButton.vue";

import ComponentTelephone from "@/components/ComponentTelephone";
import {gridUtilGetMemberNoColumnAccess} from "../../utils/gridUtil";
import HistoryPopup from "../common/HistoryPopup";
import {getPaymentLineInfo} from "@/api/systemConfig";
import moment from "moment";

export default {
  name: "DepositRegistration",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    ComponentTelephone,
    InputTextarea,
    InputText,
    EjsGridWrapper,
    InputDateRange,
    ejsGridWrapper,
    InputDate,
    depositIncomPopup,
    depositCardPopup,
    depositUseHistoryPopup,
    memberSelectPopup,
    groupPopup,
    reservationInfoPopup,
    ReportView,
    payCancelPopup,
    HistoryPopup,
    ErpButton
  },
  created() {
    //this.inputDateRangeValue.from = getFormattedDate(moment().set('month',Number(moment().format("M")-2)).format());
    //라데나 2024.01.01부터 고정
    this.inputDateRangeValue.from = "2024-01-01";
    this.inputDateRangeValue.to = getFormattedDate(new Date());

    this.searchOptions.depositKindOptions = commonCodesGetCommonCode(
        "DEPOSIT_KIND",
        true
    );
    if (
        this.searchOptions.depositKindOptions.findIndex(
            (obj) => obj.comCode === "ALL"
        ) === -1
    ) {
      this.searchOptions.depositKindOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
  },
  async mounted() {
    await this.getTableUseStore();
    if (
        this.searchOptions.tsStoreBsnOptions.findIndex(
            (obj) => obj.comCode === "ALL"
        ) === -1
    ) {
      this.searchOptions.tsStoreBsnOptions.unshift({
        comCode: "ALL",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    this.searchConditions.storeCode = "ALL";

    this.$refs.depositIncomListGrid.filterByColumn(
        "delStatus",
        "equal",
        "정상"
    );
  },
  watch: {
    $route() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
  },
  data() {
    return {
      isHistoryPopupOpen: false,
      count: 0,
      incomCount: 0,
      cardCount: 0,
      memberPopupType: {
        RESV: "RESV",
      },
      isDepositDetailViewOpened: false,
      isSearchDetailPopupOpen: false,
      isDepositIncomPopupOpen: false,
      isDepositCardPopupOpen: false,
      isDepositUseHistoryPopupOpen: false,
      isMemberSelectPopupOpen: false,
      isGroupPopupOpen: false,
      isReservationInfoPopupOpen: false,
      isPayCancelPopupOpen: false,
      grid: [Aggregate, Filter, Resize, Group, ExcelExport, ForeignKey, Page],
      depositListPageSettings: { pageSize: 50 },
      selectionSettings: {
        type: "Single",
        mode: "Both",
        enableToggle: false,
        persistSelection: false,
      },
      labels: {
        memberName: "입금자명",
        cprName: "법인명",
        cardNo: "카드번호",
        remarks: "비고",
        depositKind: "선수금종류",
        storeCode: "매장",
        balanceAmtFlag: "잔액 없는 자료 제외",
      },
      depositCardListGridColumn: [
        {
          field: "cardId",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "cardNo",
          headerText: "카드번호",
          width: 90,
        },
        {
          field: "issuDate",
          headerText: "발급일자",
          width: 90,
          textAlign: "center",
        },
        {
          field: "expirDate",
          headerText: "만료일자",
          width: 90,
          textAlign: "center",
        },
        {
          field: "depositCardStatus",
          headerText: "상태",
          width: 90,
          valueAccessor: this.depositCardStatusValueAccess,
        },
        {
          field: "memberName",
          headerText: "사용자",
          width: 90,
        },
        {
          field: "remarks",
          headerText: "비고",
          width: 150,
        },
      ],
      depositIncomListGridColumn: [
        {
          field: "incomId",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "closeDiv",
          headerText: "마감",
          width: 60,
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "center",
        },
        {
          field: "incomDate",
          headerText: "입금일자",
          width: 90,
          textAlign: "center",
        },
        {
          field: "incomDiv",
          headerText: "입금구분",
          width: 90,
          valueAccessor: this.incomDivValueAccess,
        },
        {
          field: "depositAmt",
          headerText: "선수금",
          textAlign: "right",
          width: 90,
          type: "number",
          format: "N",
        },
        {
          field: "delStatus",
          headerText: "상태",
          width: 90,
          type: "string",
        },
        {
          field: "approvalNo",
          headerText: "승인번호",
          width: 120,
        },
        {
          field: "cardNo",
          headerText: "카드번호",
          width: 120,
        },
        {
          field: "purcName",
          headerText: "매입처",
          width: 120,
        },
        {
          field: "approvalDiv",
          headerText: "승인상태",
          width: 120,
          valueAccessor: this.approvalDivValueAccess,
        },
        {
          field: "trnscDiv",
          headerText: "거래구분",
          width: 90,
          valueAccessor: this.trnscDivValueAccess,
        },
        {
          field: "bankCode",
          headerText: "은행",
          width: 120,
          valueAccessor: this.bankCodeValueAccess,
        },
        {
          field: "acnutNo",
          headerText: "계좌번호",
          width: 120,
        },
        {
          field: "delReason",
          headerText: "취소사유",
          width: 120,
        },
        {
          field: "insertName",
          headerText: "등록자",
          width: 90,
        },
        {
          field: "insertDt",
          headerText: "등록일시",
          width: 140,
          textAlign: "center",
        },
      ],
      depositListGridColumn: [
        {
          field: "incomDate",
          headerText: "입금일자",
          textAlign: "center",
          width: 90,
          isDateType: true,
          type: "string",
        },
        {
          field: "depositId",
          headerText: "선수금ID",
          visible: true,
          isPrimaryKey: true,
          type: "string",
          textAlign: "center",
          width: 90,
        },
        {
          field: "depositKind",
          headerText: "선수금종류",
          width: 100,
          isCommonCodeField: true,
          groupCode: "DEPOSIT_KIND",
        },
        {
          field: "depositCardCount",
          headerText: "카드발급",
          textAlign: "center",
          width: 90,
          type: "string",
        },
        {
          field: "memberName",
          headerText: "입금자명",
          width: 110,
          type: "string",
        },
        {
          field: "memberNo",
          headerText: "회원번호",
          width: 90,
          type: "string",
          textAlign: "Center",
          valueAccessor: (field, data) =>
            gridUtilGetMemberNoColumnAccess(field, data),
        },
        {
          field: "cprName",
          headerText: "법인명",
          width: 90,
          type: "string",
        },
        {
          field: "remarks",
          headerText: "비고",
          width: 190,
          type: "string",
        },
        {
          headerText: "선수금현황",
          columns: [
            {
              field: "depositAmt",
              headerText: "선수금",
              textAlign: "right",
              width: 110,
              valueAccessor: this.isZeroValueAccess,
              type: "string",
            },
            {
              field: "returnAmt",
              headerText: "환불금",
              textAlign: "right",
              valueAccessor: this.isZeroValueAccess,
              width: 110,
              type: "string",
            },
            {
              field: "useAmt",
              headerText: "사용금액",
              textAlign: "right",
              valueAccessor: this.isZeroValueAccess,
              width: 110,
              type: "string",
            },
            {
              field: "balanceAmt",
              headerText: "잔액",
              textAlign: "right",
              valueAccessor: this.isZeroValueAccess,
              width: 110,
              type: "string",
            },
          ],
        },
        {
          headerText: "입금현황",
          columns: [
            {
              field: "transIncomAmt",
              headerText: "계좌이체",
              textAlign: "right",
              valueAccessor: this.isZeroValueAccess,
              width: 110,
              type: "string",
            },
            {
              field: "cardIncomAmt",
              headerText: "카드",
              textAlign: "right",
              valueAccessor: this.isZeroValueAccess,
              width: 110,
              type: "string",
            },
            {
              field: "cashIncomAmt",
              headerText: "현금",
              textAlign: "right",
              valueAccessor: this.isZeroValueAccess,
              width: 110,
              type: "string",
            },
            {
              field: "promtnIncomAmt",
              headerText: "판촉",
              textAlign: "right",
              valueAccessor: this.isZeroValueAccess,
              width: 110,
              type: "string",
            },
          ],
        },
        {
          field: "storeCode",
          headerText: "매장",
          width: 80,
          valueAccessor: this.storeCodeValueAccess,
        },
        {
          field: "grpName",
          headerText: "단체명",
          width: 90,
        },
        {
          field: "insertName",
          headerText: "등록자",
          width: 90,
        },
        {
          field: "insertDt",
          headerText: "등록일시",
          textAlign: "center",
          width: 140,
        },
      ],
      depositIncomListGridAggregates: [],
      depositListGridAggregates: [
        {
          columns: [
            {
              field: "incomDate",
              aggregationType: "TotalCaption",
              customAggregate: "합계",
            },
            {
              field: "depositAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "returnAmt",
              aggregationType: "TotalSum",
              textColor: "#ff0000",
            },
            {
              field: "useAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "balanceAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "transIncomAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "cardIncomAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "cashIncomAmt",
              aggregationType: "TotalSum",
            },
            {
              field: "promtnIncomAmt",
              aggregationType: "TotalSum",
            },
          ],
        },
      ],
      searchConditions: {
        dateDiv: "INCOM_DATE",
        bsnStartDate: null,
        bsnEndDate: null,
        memberName: null,
        cprName: null,
        cardNo: null,
        remarks: null,
        depositKind: "ALL",
        storeCode: null,
        balanceAmtFlag: false,
        isApplyByDate: false,
      },
      searchOptions: {
        dateDiv: [
          { comCode: "INCOM_DATE", comName: "입금일자" },
          { comCode: "USE_DATE", comName: "사용일자" },
        ],
        depositKindInputOptions: commonCodesGetCommonCode("DEPOSIT_KIND", true),
        storeCodeOptions: commonCodesGetCommonCode("STORE_CODE", true),
      },
      inputDateRangeValue: { to: null, from: null },
      commonCodeFields: { text: "comName", value: "comCode" },
      depositList: [],
      orgDepositInfo: {
        depositId: null,
        incomDate: getFormattedDate(new Date()),
        depositKind: commonCodeGetComCodeDefaultValue("DEPOSIT_KIND"),
        storeCode: "FRONT",
        memberName: null,
        membershipId: null,
        memberDiv: null,
        memberNo: null,
        cprName: null,
        contactTel: null,
        grpNo: null,
        grpName: null,
        resveNo: null,
        remarks: null,
      },
      depositInfo: {
        depositId: null,
        incomDate: getFormattedDate(new Date()),
        depositKind: commonCodeGetComCodeDefaultValue("DEPOSIT_KIND"),
        storeCode: "FRONT",
        memberName: null,
        membershipId: null,
        memberDiv: null,
        memberNo: null,
        cprName: null,
        contactTel: null,
        grpNo: null,
        grpName: null,
        resveNo: null,
        remarks: null,
      },
      depositIncomList: [],
      depositCardList: [],
      depositIncomPopupData: {
        incomId: null,
        depositId: null,
        storeCode: null,
      },
      depositCardPopupData: {
        cardId: null,
        depositId: null,
        depositKind: null,
      },
      depositValidationRules: {
        incomDate: {
          required: true,
        },
        depositKind: {
          required: true,
        },
        storeCode: {
          required: true,
        },
        memberName: {
          required: true,
          maxLength: 50,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["username"]),
    isPopupOpened() {
      return (
        this.isDepositIncomPopupOpen ||
        this.isDepositCardPopupOpen ||
        this.isDepositUseHistoryPopupOpen ||
        this.isMemberSelectPopupOpen ||
        this.isGroupPopupOpen ||
        this.isReservationInfoPopupOpen ||
        this.isPayCancelPopupOpen
      );
    },
  },
  methods: {
    numberWithCommas,
    validateFormRefs,
    formPreventEnterEvent,
    getDayOfWeekCaption,
    getDayOfWeekCaptionColor,
    getWeekdayWeekendCaption,
    getWeekdayWeekendCaptionColor,
    commonCodesGetComName,
    getCount(index) {
      switch (index) {
        case 0:
          this.count = numberWithCommas(
            this.$refs.depositListGrid?.getGridBatchCount() || 0
          );
          break;
        case 1:
          this.incomCount = numberWithCommas(
            this.$refs.depositIncomListGrid?.getGridBatchCount() || 0
          );
          break;
        case 2:
          this.cardCount = numberWithCommas(
            this.$refs.depositCardListGrid?.getGridBatchCount() || 0
          );
          break;
      }
    },
    isZeroValueAccess(field, data) {
      let v = data[field];
      v = v ? (v === 0 ? "-" : numberWithCommas(v)) : "-";
      return v;
    },
    storeCodeValueAccess(field, data) {
      let code = data[field];
      if (code != null) {
        code = commonCodesGetComName("STORE_CODE", code);
      }
      return code;
    },
    incomDivValueAccess(field, data) {
      let code = data[field];
      if (code != null) {
        code = commonCodesGetComName("INCOM_DIV", code);
      }
      return code;
    },
    approvalDivValueAccess(field, data) {
      let code = data[field];
      if (code != null) {
        code = commonCodesGetComName("APPROVAL_DIV", code);
      }
      return code;
    },
    trnscDivValueAccess(field, data) {
      let code = data[field];
      if (code != null) {
        code = commonCodesGetComName("TRNSC_DIV", code);
      }
      return code;
    },
    bankCodeValueAccess(field, data) {
      let code = data[field];
      if (code != null) {
        code = commonCodesGetComName("BANK_CODE", code);
      }
      return code;
    },
    depositCardStatusValueAccess(field, data) {
      let code = data[field];
      if (code != null) {
        code = commonCodesGetComName("DEPOSIT_CARD_STATUS", code);
      }
      return code;
    },
    async onDepositViewClicked() {
      this.searchConditions.bsnStartDate = this.inputDateRangeValue.from;
      this.searchConditions.bsnEndDate = this.inputDateRangeValue.to;

      if (!this.searchConditions.bsnStartDate) {
        const columnName = this.searchOptions.dateDiv.find(
          (item) => item.comCode === this.searchConditions.dateDiv
        );

        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", [
            columnName.comName,
          ])
        );
      }
      if (!this.searchConditions.bsnEndDate) {
        return this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["종료일자"])
        );
      }

      let data = Object.assign({}, this.searchConditions);

      data.depositKind = data.depositKind === "ALL" ? null : data.depositKind;
      data.storeCode = data.storeCode === "ALL" ? null : data.storeCode;

      this.depositList = await GolfErpAPI.fetchDeposits(data);
    },
    async fetchDeposit(depositId) {
      const deposit = await GolfErpAPI.fetchDeposit(depositId);

      this.depositInfo.depositId = deposit.depositId;
      this.depositInfo.incomDate =
        deposit.incomDate === undefined ? null : deposit.incomDate;
      this.depositInfo.depositKind =
        deposit.depositKind === undefined ? null : deposit.depositKind;
      this.depositInfo.storeCode =
        deposit.storeCode === undefined ? null : deposit.storeCode;
      this.depositInfo.memberName =
        deposit.memberName === undefined ? null : deposit.memberName;
      this.depositInfo.memberDiv =
        deposit.memberDiv === undefined ? null : deposit.memberDiv;
      this.depositInfo.memberNo =
        deposit.memberNo === undefined ? null : deposit.memberNo;
      this.depositInfo.cprName =
        deposit.cprName === undefined ? null : deposit.cprName;
      this.depositInfo.contactTel =
        deposit.contactTel === undefined ? null : deposit.contactTel;
      this.depositInfo.grpNo =
        deposit.grpNo === undefined ? null : deposit.grpNo;
      this.depositInfo.grpName =
        deposit.grpName === undefined ? null : deposit.grpName;
      this.depositInfo.resveNo =
        deposit.resveNo === undefined ? null : deposit.resveNo;
      this.depositInfo.remarks =
        deposit.remarks === undefined ? null : deposit.remarks;

      this.orgDepositInfo = JSON.parse(JSON.stringify(this.depositInfo));
    },
    async fetchDepositIncoms(depositId) {
      const args = {
        depositId: depositId,
        depositIncomCancelViewFlag: true,
      };

      this.depositIncomList = (await GolfErpAPI.fetchDepositIncoms(args)).map(
        (data) => ({
          ...data,
          delStatus: data.delFlag ? "취소" : "정상",
        })
      );
    },
    async fetchDepositCards(depositId) {
      this.depositCardList = await GolfErpAPI.fetchDepositCards(depositId);
    },
    async onDepositDetailViewClicked() {
      await this.onDepositViewClicked();
    },
    onSearchDetailPopupOpen() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    onSearchDetailPopupClose() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    onInitButtonClicked() {
      this.searchConditions.memberName = null;
      this.searchConditions.cprName = null;
      this.searchConditions.cardNo = null;
      this.searchConditions.remarks = null;
      this.searchConditions.depositKind = "ALL";
      this.searchConditions.storeCode = null;
      this.searchConditions.balanceAmtFlag = true;
    },
    async getTableUseStore() {
      const response = await getTableUseStore(null, this.bsnDate);
      this.searchOptions.tsStoreBsnOptions = response.value.comCodeDtlList;
    },
    async onDepositAddButtonClicked() {
      if (
        JSON.stringify(this.depositInfo) !== JSON.stringify(this.orgDepositInfo)
      ) {
        if (
          !(await this.confirm("수정된 내용이 있습니다. 초기화하시겠습니까?"))
        ) {
          return;
        }
      }
      this.isDepositDetailViewOpened = true;
      this.init();
    },
    onDepositUseReturnListButtonClicked() {
      let selectedRecords = this.$refs.depositListGrid.getSelectedRecords();

      if (selectedRecords.length === 0) {
        this.errorToast("선수금 내용을 선택해 주십시오");
        return;
      }

      this.isDepositUseHistoryPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.depositUseHistoryPopup.showDepositUseHistoryPopup({
          depositId: selectedRecords[0].depositId,
        });
      });
    },
    depositListGridQueryHeaderInfo(args) {
      const {
        cell: {column: {field}},
        node,
      } = args;
      if (field === "memberName") {
        node.classList.add(this.$t("className.grid.clickAreaLeft"));
      }
      if (
        field === "returnAmt" ||
        field === "useAmt"
      ) {
        node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    depositListGridQueryCellInfo(args) {
      const {
        column: {field},
        cell,
        data,
      } = args;
      if (field === "memberName") {
        cell.classList.add(this.$t("className.grid.clickAreaLeft"));
      }
      if (
        field === "returnAmt" ||
        field === "useAmt"
      ) {
        cell.classList.add(this.$t("className.grid.clickArea"));
      }
      if (field === "returnAmt") {
        if (data[field] > 0) {
          cell.style.color = "#ff0000";
        }
      }
    },
    depositIncomListGridqueryHeaderInfo(args) {
      const column = args.cell.column.field;
      if (column === "incomDate") {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    depositIncomListGridqueryCellInfo(args) {
      const column = args.column.field;
      const cellData = args.data;
      if (column === "incomDate") {
        args.cell.classList.add(this.$t("className.grid.clickArea"));
      } else if (column === "delStatus") {
        if (cellData.delFlag) {
          args.cell.style.color = commonCodesGetColorValue(
            "APPROVAL_DIV",
            "CANCEL"
          );
        } else {
          args.cell.style.color = commonCodesGetColorValue(
            "APPROVAL_DIV",
            "OK"
          );
        }
      } else if (column === "approvalDiv") {
        args.cell.style.color = commonCodesGetColorValue(
          "APPROVAL_DIV",
          cellData.approvalDiv
        );
      }

      if (cellData.delFlag) {
        args.cell.style.setProperty("text-decoration", "line-through");
      }
    },
    depositCardListGridHeaderCellInfo(args) {
      const column = args.cell.column.field;
      if (column === "cardNo") {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    depositCardListGridQueryCellInfo(args) {
      const column = args.column.field;
      const cellData = args.data;
      if (column === "cardNo") {
        args.cell.classList.add(this.$t("className.grid.clickArea"));
      }
      if (column === "depositCardStatus") {
        args.cell.style.color = commonCodesGetColorValue(
          "DEPOSIT_CARD_STATUS",
          cellData[column]
        );
      }
    },
    async onDepositListGridRowSelected(args) {
      console.log("Test");
      const depositId = args.data.depositId;

      await Promise.all([
        this.fetchDeposit(depositId),
        this.fetchDepositIncoms(depositId),
        this.fetchDepositCards(depositId)
      ]);

      this.isDepositDetailViewOpened = true;
    },
    onDepositListGridRecordClick(args) {
      const {
        column: {
          field
        },
        rowData
      } = args;

      if (
        field === "returnAmt" ||
        field === "useAmt"
      ) {
        this.isDepositUseHistoryPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.depositUseHistoryPopup.showDepositUseHistoryPopup({
            depositId: rowData.depositId,
          });
        });
      }
    },
    onDepositIncomListGridRecordClick(args) {
      const {
        column: {
          field
        },
        rowData
      } = args;

      if (field === "incomDate") {
        this.depositIncomPopupData.incomId = rowData.incomId;
        this.depositIncomPopupOpen();
      }
    },
    onDepositCardListGridRecordClick(args) {
      const {
        column: {
          field
        },
        rowData
      } = args;

      if (field === "cardNo") {
        this.depositCardPopupData.cardId = rowData.cardId;
        this.depositCardPopupData.depositId = this.depositInfo.depositId;
        this.depositCardPopupData.depositKind = this.depositInfo.depositKind;
        this.depositCardPopupOpen();
      }
    },
    init() {
      // 선수금 정보 초기화.
      this.depositInfo.depositId = null;
      this.depositInfo.incomDate = getFormattedDate(new Date());
      this.depositInfo.depositKind = commonCodeGetComCodeDefaultValue(
        "DEPOSIT_KIND"
      );
      this.depositInfo.storeCode = "FRONT";
      this.depositInfo.memberName = null;
      this.depositInfo.membershipId = null;
      this.depositInfo.memberDiv = null;
      this.depositInfo.memberNo = null;
      this.depositInfo.cprName = null;
      this.depositInfo.contactTel = null;
      this.depositInfo.grpNo = null;
      this.depositInfo.grpName = null;
      this.depositInfo.resveNo = null;
      this.depositInfo.remarks = null;

      this.orgDepositInfo.depositId = null;
      this.orgDepositInfo.incomDate = getFormattedDate(new Date());
      this.orgDepositInfo.depositKind = commonCodeGetComCodeDefaultValue(
        "DEPOSIT_KIND"
      );
      this.orgDepositInfo.storeCode = "FRONT";
      this.orgDepositInfo.memberName = null;
      this.orgDepositInfo.membershipId = null;
      this.orgDepositInfo.memberDiv = null;
      this.orgDepositInfo.memberNo = null;
      this.orgDepositInfo.cprName = null;
      this.orgDepositInfo.contactTel = null;
      this.orgDepositInfo.grpNo = null;
      this.orgDepositInfo.grpName = null;
      this.orgDepositInfo.resveNo = null;
      this.orgDepositInfo.remarks = null;

      // 입금 취소 보기 체크박스 초기화.

      // 선수금 입금 목록 그리드 초기화.
      this.depositIncomList = [];

      // 선수금 카드 발행 목록 그리드 초기화.
      this.depositCardList = [];

      // this.$nextTick(() => {
      //   this.$refs.memberName.focusIn();
      // });
    },
    async depositIncomPopupClosed({isSaveFlag,saveId}) {
      console.log("emit",isSaveFlag,saveId);
      this.isDepositIncomPopupOpen = false;
      if (isSaveFlag) {
        this.depositInfo.depositId = saveId;
        console.log("flag===>", this.depositInfo.depositId);
        await this.onDepositViewClicked();
      }
    },
    async depositCardPopupClosed(isSaveFlag) {
      this.isDepositCardPopupOpen = false;

      if (isSaveFlag) {
        await this.onDepositViewClicked();
      }
    },
    depositUseHistoryPopupClosed() {
      this.isDepositUseHistoryPopupOpen = false;
    },
    depositIncomPopupOpen() {
      if (!this.validateFormRefs(this.depositValidationRules)) {
        return;
      }

      let deposit = JSON.parse(JSON.stringify(this.depositInfo));

      deposit.incomDate =
        !deposit.incomDate || deposit.incomDate.trim() === ""
          ? null
          : deposit.incomDate;
      deposit.depositKind =
        !deposit.depositKind || deposit.depositKind.trim() === ""
          ? null
          : deposit.depositKind;
      deposit.storeCode =
        !deposit.storeCode || deposit.storeCode.trim() === ""
          ? null
          : deposit.storeCode;
      deposit.memberName =
        !deposit.memberName || deposit.memberName.trim() === ""
          ? null
          : deposit.memberName;
      deposit.memberDiv =
        !deposit.memberDiv || deposit.memberDiv.trim() === ""
          ? null
          : deposit.memberDiv;
      deposit.memberNo =
        !deposit.memberNo || deposit.memberNo.trim() === ""
          ? null
          : deposit.memberNo;
      deposit.cprName =
        !deposit.cprName || deposit.cprName.trim() === ""
          ? null
          : deposit.cprName;
      deposit.contactTel =
        !deposit.contactTel || deposit.contactTel.trim() === ""
          ? null
          : deposit.contactTel;
      deposit.grpNo =
        !deposit.grpNo || deposit.grpNo.trim() === "" ? null : deposit.grpNo;
      deposit.grpName =
        !deposit.grpName || deposit.grpName.trim() === ""
          ? null
          : deposit.grpName;
      deposit.resveNo =
        !deposit.resveNo || deposit.resveNo.trim() === ""
          ? null
          : deposit.resveNo;
      deposit.remarks =
        !deposit.remarks || deposit.remarks.trim() === ""
          ? null
          : deposit.remarks;

      this.depositIncomPopupData.storeCode = this.depositInfo.storeCode;
      this.depositIncomPopupData.deposit = deposit;
      this.isDepositIncomPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.depositIncomPopup.showDepositIncomPopup(
          this.depositIncomPopupData
        );
      });
    },
    onDepositIncomPopupOpenClicked() {
      this.depositIncomPopupData.incomId = null;
      this.depositIncomPopupData.depositId = this.depositInfo.depositId;
      this.depositIncomPopupOpen();
    },
    depositCardPopupOpen() {
      this.isDepositCardPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.depositCardPopup.showDepositCardPopup(
          this.depositCardPopupData
        );
      });
    },
    onDepositCardPopupOpenClicked() {
      if (this.depositInfo.depositId) {
        this.depositCardPopupData.cardId = null;
        this.depositCardPopupData.depositId = this.depositInfo.depositId;
        this.depositCardPopupData.depositKind = this.depositInfo.depositKind;
        this.depositCardPopupData.memberName = this.depositInfo.memberName;
        this.depositCardPopupData.membershipId = this.depositInfo.membershipId;
        this.depositCardPopupData.memberNo = this.depositInfo.memberNo;
        this.depositCardPopupData.cprName = this.depositInfo.cprName;
        this.depositCardPopupOpen();
      } else {
        this.errorToast(
          this.$t("creditManagement.popupMessage.notExistDepositInfo")
        );
      }
    },
    async onDepositSaveButtonClicked() {
      if (!this.validateFormRefs(this.depositValidationRules)) {
        return;
      }

      if (!this.depositInfo.depositId) {
        this.onDepositIncomPopupOpenClicked();
        return;
      }

      let deposit = JSON.parse(JSON.stringify(this.depositInfo));

      deposit.incomDate =
        !deposit.incomDate || deposit.incomDate.trim() === ""
          ? null
          : deposit.incomDate;
      deposit.depositKind =
        !deposit.depositKind || deposit.depositKind.trim() === ""
          ? null
          : deposit.depositKind;
      deposit.storeCode =
        !deposit.storeCode || deposit.storeCode.trim() === ""
          ? null
          : deposit.storeCode;
      deposit.memberName =
        !deposit.memberName || deposit.memberName.trim() === ""
          ? null
          : deposit.memberName;
      deposit.memberDiv =
        !deposit.memberDiv || deposit.memberDiv.trim() === ""
          ? null
          : deposit.memberDiv;
      deposit.memberNo =
        !deposit.memberNo || deposit.memberNo.trim() === ""
          ? null
          : deposit.memberNo;
      deposit.cprName =
        !deposit.cprName || deposit.cprName.trim() === ""
          ? null
          : deposit.cprName;
      deposit.contactTel =
        !deposit.contactTel || deposit.contactTel.trim() === ""
          ? null
          : deposit.contactTel;
      deposit.grpNo =
        !deposit.grpNo || deposit.grpNo.trim() === "" ? null : deposit.grpNo;
      deposit.grpName =
        !deposit.grpName || deposit.grpName.trim() === ""
          ? null
          : deposit.grpName;
      deposit.resveNo =
        !deposit.resveNo || deposit.resveNo.trim() === ""
          ? null
          : deposit.resveNo;
      deposit.remarks =
        !deposit.remarks || deposit.remarks.trim() === ""
          ? null
          : deposit.remarks;

      await this.patchDeposit(deposit);
    },
    async patchDeposit(deposit) {
      const data = await GolfErpAPI.patchDeposit(deposit);

      this.depositInfo.depositId = data.depositId;
      this.depositInfo.incomDate =
        data.incomDate === undefined ? null : data.incomDate;
      this.depositInfo.depositKind =
        data.depositKind === undefined ? null : data.depositKind;
      this.depositInfo.storeCode =
        data.storeCode === undefined ? null : data.storeCode;
      this.depositInfo.memberName =
        data.memberName === undefined ? null : data.memberName;
      this.depositInfo.memberDiv =
        data.memberDiv === undefined ? null : data.memberDiv;
      this.depositInfo.memberNo =
        data.memberNo === undefined ? null : data.memberNo;
      this.depositInfo.cprName =
        data.cprName === undefined ? null : data.cprName;
      this.depositInfo.contactTel =
        data.contactTel === undefined ? null : data.contactTel;
      this.depositInfo.grpNo = data.grpNo === undefined ? null : data.grpNo;
      this.depositInfo.grpName =
        data.grpName === undefined ? null : data.grpName;
      this.depositInfo.resveNo =
        data.resveNo === undefined ? null : data.resveNo;
      this.depositInfo.remarks =
        data.remarks === undefined ? null : data.remarks;

      this.infoToast(this.$t("main.popupMessage.saved"));
    },
    memberSelectPopupClosed() {
      this.isMemberSelectPopupOpen = false;

      this.setNonMemberInfos();
    },
    memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false;

      if (data.selectedRowData) {
        if (!data.selectedRowData.membershipId) {
          this.setNonMemberInfos();
        } else {
          this.setMemberInfos(data.selectedRowData);
        }
      }
    },
    onMemberNameChanged(args) {
      if (this.commonMixinIsButtonDisableByAuth("depositReg")) {
        return;
      }

      if (args.event && args.previousValue !== args.value) {
        if (args.value.trim() === "") {
          // 빈 칸인 경우에는 미 설정으로
          this.setNonMemberInfos();
          return;
        }

        this.onMemberPopupOpen(
          false,
          this.memberPopupType.RESV,
          args.value,
          null
        );
      }
    },
    setMemberInfos(memberInfo) {
      this.depositInfo.memberName = memberInfo.memberName;
      this.depositInfo.membershipId = memberInfo.membershipId;
      this.depositInfo.memberDiv = memberInfo.memberDiv;
      this.depositInfo.memberNo = memberInfo.memberNo;
      this.depositInfo.contactTel = memberInfo.hpNo;
    },
    setNonMemberInfos() {
      this.depositInfo.membershipId = null;
      this.depositInfo.memberDiv = "NOM";
      this.depositInfo.memberNo = null;
    },
    onMemberPopupOpen(
      isOpenedWithButton,
      popupType,
      memberNameNo,
      timeInfoIdx,
      selectedIdx
    ) {
      this.isMemberSelectPopupOpen = true;

      let memberData = {};
      memberData.memberNameNo = memberNameNo;
      memberData.isOpenedWithButton = isOpenedWithButton;
      memberData.popupType = popupType;
      if (timeInfoIdx !== null && timeInfoIdx !== undefined) {
        memberData.timeInfoIdx = timeInfoIdx;
      }
      if (selectedIdx !== null && selectedIdx !== undefined) {
        memberData.selectedIdx = selectedIdx;
      }
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    onGroupPopupOpen(isOpenedWithButton = false) {
      this.isGroupPopupOpen = true;

      let groupPopupData = {};
      groupPopupData.grpName = this.depositInfo.grpName
        ? (groupPopupData.grpName = this.depositInfo.grpName)
        : null;
      if (isOpenedWithButton) {
        groupPopupData.isOpenedWithButton = isOpenedWithButton;
      }
      this.$nextTick(() => {
        this.$refs.groupPopup.openPopup(groupPopupData);
      });
    },
    onGroupPopupClosed(event) {
      this.isGroupPopupOpen = false;

      // [단체 Case 분기(항목은 ReservationInfo.java 참고)]
      // 단체 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우)
      //      : 단체번호, 단체종류, 단체명 설정. 예약자명 = 단체명. 연락자명, 연락처 설정(총무 정보로)
      //      : 회원권 ID, 회원번호 Clear, 성별코드(M)
      //      : 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정
      // 단체 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 단체 팝업을 선택하지 않고 닫는다)
      //      : 단체번호, 단체종류, 단체명 Clear
      //      : 나머지는 그대로 놔둠
      // console.log(JSON.stringify(event, null, 2))
      if (event.popupData.isOpenedWithButton) {
        // 단체검색으로 팝업을 Open 한 경우 : 검색하여 선택한 경우에만 처리함
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
        }
      } else {
        // 단체 text 창 입력으로 팝업을 Open 한 경우
        if (event.selectedGroup) {
          this.setGrpInfos(event.selectedGroup);
        } else {
          this.clearGrpInfo();
        }
      }
    },
    setGrpInfos(grpInfo) {
      this.depositInfo.grpNo = grpInfo.grpNo;
      this.depositInfo.grpName = grpInfo.grpName;
      if (grpInfo.generName) {
        this.depositInfo.memberName = grpInfo.generName;
        this.depositInfo.memberDiv = "NOM";
        this.depositInfo.memberNo = null;
      }
    },
    clearGrpInfo() {
      this.depositInfo.grpNo = null;
      this.depositInfo.grpName = null;
    },
    async onGrpNameChanged(args) {
      if (this.commonMixinIsButtonDisableByAuth("depositReg")) {
        return;
      }
      if (args.event && args.previousValue !== args.value) {
        // alert(args.value + '로 검색한 건이 0건이면 관련 정보 Clear, 1건이면 해당 건을 단체로 설정, 2건 이상이면 단체 팝업을 띄운다')
        // [단체 Case 분기(항목은 ReservationInfo.java 참고)]
        // 단체 검색된 경우(1건 검색시 또는 회원정보 팝업에서 선택한 경우)
        //      : 단체번호, 단체종류, 단체명 설정. 예약자명 = 단체명. 연락자명, 연락처 설정(총무 정보로)
        //      : 회원권 ID, 회원번호 Clear, 성별코드(M)
        //      : 회원구분(비회원), 회원등급(회원구분 - 비회원 Record의 JSON_DATA 컬럼 중 IDX:4 인 값의 ATTRB로 설정) 으로 설정
        // 단체 검색되지 않은 경우(검색한 건이 0건이거나, 띄운 단체 팝업을 선택하지 않고 닫는다)
        //      : 단체번호, 단체종류, 단체명 Clear
        //      : 나머지는 그대로 놔둠
        if (args.value.trim() === "") {
          // 빈 칸인 경우에는 미 설정으로
          this.clearGrpInfo();
          return;
        }
        const response = await getGroupList({ searchValue: args.value });
        const groupList = response.value.groupInfoList;
        if (groupList && groupList.length > 1) {
          this.onGroupPopupOpen();
        } else if (groupList && groupList.length === 1) {
          // 그룹정보 설정
          this.setGrpInfos(groupList[0]);
        } else {
          // 단체명 입력시 조회 데이터 없을 경우 알럿 (GE-1556)
          this.errorToast("등록된 단체 정보가 없습니다");
          this.$nextTick(() => {
            this.$refs["grpName"].$el.focus();
          });

          // 그룹정보 clear
          this.clearGrpInfo();
        }
      }
    },
    onReservationInfoPopupClose() {
      this.isReservationInfoPopupOpen = false;
    },
    viewReservationInfo() {
      this.isReservationInfoPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.reservationInfoPopup.showReservationInfoPopup(
          this.depositInfo.incomDate,
          this.depositInfo.incomDate,
          null,
          null,
          this.depositInfo.resveNo
        );
      });
    },
    onResveNoChange(args) {
      if (this.commonMixinIsButtonDisableByAuth("depositReg")) {
        return;
      }
      if (args.value.trim() === "") {
        // 빈 칸인 경우에는 미 설정으로
        return;
      }
      if (args.event && args.previousValue !== args.value) {
        this.viewReservationInfo();
      }
    },
    onReservationInfoPopupConfirmed(data) {
      this.isReservationInfoPopupOpen = false;

      if (data) {
        this.depositInfo.resveNo = data.resveNo;
      }
    },
    onDepositListGridDataBound() {
      console.log("onDepositListGridDataBound");
      console.log("depositId===>",this.depositInfo.depositId);
      if (this.depositInfo.depositId) {
        const idx = this.depositList.findIndex(
          (deposit) => deposit.depositId === this.depositInfo.depositId
        );
        const currentPageList = this.$refs.depositListGrid.getCurrentViewRecords();
        console.log("currentPageList",currentPageList);
        const currentPageFindIdx = currentPageList.some((data) => data.depositId === this.depositInfo.depositId);
        console.log("currentPageFindIdx",currentPageFindIdx);
       if(idx && currentPageFindIdx) {
         console.log("if=-===>");
          this.$refs.depositListGrid.selectRow(idx);
        } else {
         console.log("else=-===>");
         // this.onDepositDetailViewClosed();
         this.$nextTick(() => {
           this.onDepositDetailViewClosed();
         });
       }
      } else {
        this.$nextTick(() => {
          this.onDepositDetailViewClosed();
        });
      }
    },
    depositRefresh(rowIndex) {
      if (rowIndex !== null && rowIndex !== undefined && rowIndex >= 0) {
        this.$refs.depositListGrid.selectRow(rowIndex);
      } else {
        this.onDepositDetailViewClosed();
      }
    },
    async onDepositDeleteButtonClicked() {
      if (!this.depositInfo.depositId) {
        this.errorToast(this.$t("creditManagement.popupMessage.notExistDepositInfo"));
        return;
      }

      if (!(await this.confirm(this.$t("main.popupMessage.confirmDelete")))) {
        return;
      }

      await this.deleteDeposit(this.depositInfo.depositId);
      await this.onDepositViewClicked();
      await this.onDepositDetailViewClosed();
    },
    async deleteDeposit(depositId) {
      await GolfErpAPI.deleteDeposit(depositId);
    },
    onChangeHistoryButtonClicked() {
      if (!this.depositInfo.depositId) {
        return;
      }

      GolfErpAPI.fetchDepositHistory(this.depositInfo.depositId).then((rawHistories) => {
        console.log('rawHistories.===>', rawHistories);
        this.isHistoryPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.historyPopup.show({
            rawHistories,
            fields: {
              Deposit: {
                __name__: "선수금 정보",
                incomDate: {
                  name: "입금일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                storeCode: {
                  name: "매장코드",
                  type: "commonCode",
                  groupCode: "STORE_CODE",
                  methods: ["create", "update", "delete"],
                },
                depositKind: {
                  name: "선수금종류",
                  type: "commonCode",
                  groupCode: "DEPOSIT_KIND",
                  methods: ["create", "update", "delete"],
                },
                resveNo: {
                  name: "예약번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberName: {
                  name: "회원명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                cprName: {
                  name: "법인명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                membershipId: {
                  name: "회원권ID",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberNo: {
                  name: "회원번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberDiv: {
                  name: "회원구분",
                  type: "commonCode",
                  groupCode: "MEMBER_DIV",
                  methods: ["create", "update", "delete"],
                },
                contactTel: {
                  name: "연락처",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                grpNo: {
                  name: "단체번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                grpName: {
                  name: "단체명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                remarks: {
                  name: "비고",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
              },
              DepositIncom: {
                __name__: "선수금 입금 정보",
                incomDate: {
                  name: "입금일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                incomDiv: {
                  name: "입금구분",
                  type: "commonCode",
                  groupCode: "INCOM_DIV",
                  methods: ["create", "update", "delete"],
                },
                trnscDiv: {
                  name: "거래구분",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                indctTrgetFlag: {
                  name: "소득공제대상여부",
                  type: "boolean",
                  methods: ["create", "update", "delete"],
                },
                notaxCode: {
                  name: "면세코드",
                  type: "commonCode",
                  groupCode: "NOTAX_CODE",
                  methods: ["create", "update", "delete"],
                },
                approvalNo: {
                  name: "승인번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                depositAmt: {
                  name: "선수금액",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                taxAmt: {
                  name: "과세금액",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                notaxAmt: {
                  name: "면세금액",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                vatAmt: {
                  name: "부가세금액",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                svcAmt: {
                  name: "봉사료",
                  type: "number",
                  methods: ["create", "update", "delete"],
                },
                bankCode: {
                  name: "은행코드",
                  type: "commonCode",
                  groupCode: "BANK_CODE",
                  methods: ["create", "update", "delete"],
                },
                acnutNo: {
                  name: "계좌번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                bsnId: {
                  name: "영업장ID",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                posNo: {
                  name: "POS번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                termId: {
                  name: "단말ID",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
              },
              DepositCard: {
                __name__: "선수금 카드 정보",
                cardNo: {
                  name: "카드번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                depositCardStatus: {
                  name: "선수금카드상태",
                  type: "commonCode",
                  groupCode: "DEPOSIT_CARD_STATUS",
                  methods: ["create", "update", "delete"],
                },
                issuDate: {
                  name: "발급일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                expirDate: {
                  name: "만료일자",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberName: {
                  name: "회원명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                cprName: {
                  name: "법인명",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                membershipId: {
                  name: "회원권ID",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                memberNo: {
                  name: "회원번호",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
                remarks: {
                  name: "비고",
                  type: "string",
                  methods: ["create", "update", "delete"],
                },
              }
            }
          });
        });
      });
    },
    historyPopupClosed() {
      this.isHistoryPopupOpen = false;
    },
    onDepositDetailViewClosed() {
      this.init();
      this.isDepositDetailViewOpened = false;
    },
    depositListGridActionComplete() {
      this.getCount(0);
    },
    depositIncomListGridActionComplete() {
      this.getCount(1);
    },
    depositCardListGridActionComplete() {
      this.getCount(2);
    },
    async print() {
      const options = [
        {
          field: "depositKind",
          comCode: "DEPOSIT_KIND",
          format: "comCode",
        },
        {
          field: "storeCode",
          comCode: "STORE_CODE",
          format: "comCode",
        },
      ];

      const result = this.$refs.depositListGrid.getGridBatchData(options);
      if (result.length < 1) {
        return this.errorToast(this.$t("report.popupMessage.noData"));
      }
      const [{ tsConfSanctnDetail }] = (await getPaymentLineInfo(true)).value.sanctnList.filter((data)=> data.sanctnLineId === 5 );
      const searchOptionsList = [
        {
          key: this.searchOptions.dateDiv.find(
            (item) => item.comCode === this.searchConditions.dateDiv
          ).comName,
          value:
            this.inputDateRangeValue.from + " ~ " + this.inputDateRangeValue.to,
        },
        {
          key: this.labels.memberName,
          value: this.searchConditions.memberName,
        },
        {
          key: this.labels.cprName,
          value: this.searchConditions.cprName,
        },
        {
          key: this.labels.cardNo,
          value: this.searchConditions.cardNo,
        },
        {
          key: this.labels.remarks,
          value: this.searchConditions.remarks,
        },
        {
          key: this.labels.depositKind,
          value: commonCodesGetComName(
            "DEPOSIT_KIND",
            this.searchConditions.depositKind
          ),
        },
        {
          key: this.labels.storeCode,
          value: commonCodesGetComName(
            "STORE_CODE",
            this.searchConditions.storeCode
          ),
        },
        {
          key: this.labels.balanceAmtFlag,
          value: this.searchConditions.balanceAmtFlag ? "예" : null,
        },
      ];

      const searchOptions = searchOptionsList
        .filter((item) => !!item.value)
        .map((item) => item.key + ": " + item.value)
        .join(", ");

      const reportData = {
        reportJson: {
          jsonData: result,
          username: this.username,
          uniqueCode: this.$options.name,
          selectSanctnLineList: tsConfSanctnDetail,
          searchOptions,
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    onClickExcel() {
      this.$refs.depositListGrid.excelExport();
    },
    depositIncomCancelButtonClicked() {
      const selectedRecords = this.$refs.depositIncomListGrid.getSelectedRecords();
      if (!(selectedRecords.length > 0)) {
        this.errorToast(this.$t("creditManagement.popupMessage.notExistDepositIncom"));
        return;
      }

      this.incomCancel(selectedRecords[0]);
    },
    incomCancel(data) {
      this.isPayCancelPopupOpen = true;

      let popupData = {
        salesDiv: "DEPOSIT",
        payDiv: data.incomDiv,
        incomId: data.incomId,
        payDate: data.incomDate,
        payAmt: data.depositAmt,
        cardId: data.cardId,
        storeCode: this.depositInfo.storeCode,
        isOnlyPayDataCancel:
            data.approvalDiv === "CANCEL"
      };

      this.$nextTick(() => {
        this.$refs.payCancelPopup.showPayCancelPopup(popupData);
      });
    },
    async payCancelPopupClosed(args) {
      this.isPayCancelPopupOpen = false;

      if (args !== undefined && args.isReload) {
        await this.onDepositViewClicked();
      }
    }
  },
};
</script>
